import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
// Layout component
import Layout from '../components/layouts/Layout';
import HorizontalMenu from '../components/HorizontalMenu';
import './privacy-policy.css';

const IndexPage = () => {
    const { t } = useTranslation('privacyPolicy');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_POLICY')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_POLICY')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{__html: t('TITLE')}}></h1>
                    <HorizontalMenu
                        list={[
                            {
                                url: '/privacy-policy/',
                                isActive: true,
                                category: t('PRIVACY_POLICY')
                            },
                            {
                                url: '/terms-of-use/',
                                category: t('TERM_OF_USE')
                            },
                            {
                                url: '/terms-of-use-pro/',
                                category: t('TERM_OF_USE_PRO')
                            },
                            {
                                url: '/privacy-policy-rueil/',
                                category: t('PRIVACY_POLICY_RUEIL')
                            },
                            {
                                url: '/privacy-policy-mission-herault/',
                                category: t('PRIVACY_POLICY_MISSION_HERAULT')
                            },
                            {
                                url: '/privacy-policy-paris-region-aventures/',
                                category: t('PRIVACY_POLICY_PARIS_REGION_AVENTURES')
                            },
                            {
                                url: '/privacy-policy-colmar-aventure/',
                                category: t('PRIVACY_POLICY_COLMAR_AVENTURE')
                            },
                            {
                                url: '/privacy-policy-mont-saint-michel-aventures/',
                                category: t('PRIVACY_POLICY_MONT_SAINT_MICHEL_AVENTURE')
                            }
                        ]}
                    />
                    <h2>{t('TITLE_PERSONAL_DATA')}</h2>

                    <p>{t('TITLE_POSTING_DATE')}</p>

                    <p>{t('TITLE_WELCOME')}</p>

                    <p>{t('TITLE_WEMAP_SAS')}</p>

                    <p>{t('TITLE_WEMAP_PROTECTION_DATA')}</p>

                    <p>{t('TITLE_GATHERING_OF_DATA')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_GENERAL_TERMS')}}></p>

                    <p>{t('TITLE_MAY_INCLUDE')}</p>

                    <h2>{t('TITLE_DATA_CONTROLLER')}</h2>

                    <p>{t('TITLE_FRENCH_DATA_PROTECTION')}</p>

                    <p>{t('TITLE_INFOS_REGISTERED')}</p>

                    <h2>{t('TITLE_GATHER_PERSONAL_DATA')}</h2>

                    <p>{t('TITLE_BROWSE_OUR_SITE')}</p>

                    <h3>{t('TITLE_BROWSING_SITE')}</h3>

                    <p>{t('TITLE_GATHERS_DATA_DEVICE')}</p>

                    <h3>{t('TITLE_CREATION_ACCOUNT')}</h3>

                    <p>{t('TITLE_ASK_YOU_CREATE_ACCOUNT')}</p>

                    <p>{t('TITLE_FOLLOWING_INFOS')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_GATHERED_INFOS')}}></p>

                    <p>{t('TITLE_GATHERED_SOCIAL')}</p>

                    <p>{t('TITLE_COLLECT_USE')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_GATHERED_USE_OUR_SERVICES')}}></p>

                    <p>{t('TITLE_PROVIDE_US')}</p>

                    <h2>{t('TITLE_3_DATA')}</h2>

                    <p>{t('DESCRIPTION_3_PERSONAL_DATA')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_PERSONAL_DATA_USED')}}></p>

                    <p>{t('TITLE_CROSS_REFERENCED')}</p>

                    <h2>{t('TITLE_4_SHARE_PERSONAL_DATA')}</h2>

                    <h3>{t('TITLE_PROVISION')}</h3>

                    <p>{t('TITLE_THIRD_PARTIES')}</p>

                    <p>{t('TITLE_AFFILIATED_COMPANIES')}</p>

                    <p>{t('TITLE_WEMAP_MERGES')}</p>

                    <p>{t('TITLE_DISCLOSE_PERSONAL_DATA')}</p>

                    <h3>{t('TITLE_VISIBILITY')}</h3>

                    <p>{t('TITLE_ALWAYS_PUBLIC')}</p>

                    <h3>{t('TITLE_4_SOCIAL_NETWORK')}</h3>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_THIRD_PARTY_SOCIAL')}}></p>

                    <h2>{t('TITLE_COOKIES')}</h2>

                    <h3>{t('TITLE_WHAT_IS_COOKIES')}</h3>

                    <p>{t('TITLE_EXPLAIN_COOKIES')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_COOKIES_IMPORTANT_WARNING')}}></p>

                    <h3>{t('TITLE_WHY_USE_COOKIES')}</h3>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_COOKIES_ENABLE')}}></p>

                    <p>{t('TITLE_SAVING_COOKIE')}</p>

                    <p>{t('TITLE_FUNCTIONALITY_COOKIES')}</p>

                    <p>{t('TITLE_COOKIES_NOT_REQUIRED')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_WEB_ANALYTICS')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_RUN_APP')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_DO_NOT_AGREE')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_SHARE_COOKIE')}}></p>

                    <p>{t('TITLE_BUTTON_SHARE')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_PRIVATE_SOCIAL')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_THIRD_COOKIE')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_EXPLOITATION_SERVICES')}}></p>

                    <h3>{t('TITLE_WHY_USE_COOKIES')}</h3>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_ALLOW_SAVING_COOKIES')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_INTERNET_EXPLORER')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_SAFARI')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_CHROME')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_FIREFOX')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_OPERA')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_ANALYTICS_BLOCKED')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_BUTTON_SOCIAL')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_BUTTON_TWITTER')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_PUB_PROTECTION_PRIVATE')}}></p>

                    <h2>{t('TITLE_LOCATION_DATA')}</h2>

                    <p>{t('TITLE_LOCATION_SERVICES')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_VARIOUS_TECHNOLOGIES')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_GEO_LOCATION')}}></p>

                    <h2>{t('TITLE_MY_PERSONAL_DATA_PROTECTED')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_ORGANIZATIONAL')}}></p>

                    <h2>{t('TITLE_PERSONAL_DATA_OPTION')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_INVITE_FRIENDS')}}></p>

                    <h2>{t('TITLE_9_CASE_CONTACT_ME')}</h2>

                    <h3>{t('TITLE_OUR_PRODUCTS')}</h3>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_CONNECTION')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_CONFIGURING')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_SENDING_US_EMAIL')}}></p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_UNSUBSCRIBE')}}></p>

                    <h3>{t('TITLE_SERVICE_OPERATION')}</h3>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_PERIODICALLY')}}></p>

                    <h2>{t('TITLE_ACCESS_PERSONAL_DATA')}</h2>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_RIGHT_ACCESS')}}></p>

                    <h2>{t('TITLE_NOTIFICATION')}</h2>

                    <p>{t('TITLE_SETTING_MOBILE_DEVICE')}</p>

                    <h2>{t('TITLE_MODIFICATION')}</h2>

                    <p>{t('TITLE_AMENDMENT_POLICY')}</p>

                    <h2>{t('TITLE_CONTACT_US')}</h2>

                    <p style={{ marginBottom: '13em' }}
                        dangerouslySetInnerHTML={{__html: t('DESCRIPTION_CONTACT_US')}}></p>

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;
